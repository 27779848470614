<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <div class="row">
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-autocomplete solo dense class="ma-0 pa-2" hide-details=true v-model="regionModel" :items="regionLists" item-value="region" item-text="region" label="Region" 
                                    :disabled="disabled_region" @change="(event) => updateOfficeDropdown(event)" clearable prepend-icon="mdi-city"></v-autocomplete>
                            </v-col>
                            <!-- <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" 
                                :items="regionLists" 
                                default="" 
                                item-value="region" 
                                item-text="region" 
                                label="Region" 
                                :disabled="disabled_region" 
                                @change="(event) => updateOfficeDropdown(event)" 
                                clearable dense prepend-icon="mdi-city">
                            </v-select>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-autocomplete solo dense class="ma-0 pa-2" hide-details hide-no-data v-model="officeModel" :items="officeLists" item-value="office_id" item-text="office" label="Branch" 
                                    :disabled="disabled_office" @change="(event) => updateSalesmanDropdown(event)" clearable prepend-icon="mdi-home-variant"></v-autocomplete>
                          </v-col>
                            <!-- <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" 
                                :items="officeLists" 
                                default="" 
                                item-value="office_id" 
                                item-text="office" 
                                label="branch" 
                                :disabled="disabled_office" 
                                @change="(event) => updateSalesmanDropdown(event)" 
                                clearable dense prepend-icon="mdi-home-variant">
                            </v-select>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-autocomplete solo dense class="ma-0 pa-2" hide-details hide-no-data v-model="salesmanModel" :items="salesmanLists" item-value="SalesId" item-text="NamaSales" label="Salesman" 
                                    :disabled="disabled_salesman" clearable prepend-icon="mdi-account"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-autocomplete solo dense class="ma-0 pa-2" cache-items allow-overflow hide-details hide-no-data v-model="findCustomerModel" :items="datas" :search-input.sync="search" item-value="CustomerId" item-text="NamaCustomer" 
                                    label="Customer Name" clearable prepend-icon="mdi-rowing"></v-autocomplete>
                            </v-col>
                            <!-- <div class="col-lg-2">
                                <v-select v-model="salesmanModel" 
                                :items="salesmanLists" 
                                default="" 
                                item-value="SalesId" 
                                item-text="NamaSales" 
                                label="Salesman" 
                                :disabled="disabled_salesman" 
                                clearable 
                                dense 
                                prepend-icon="mdi-account"></v-select>
                            </div> -->
                            <!-- <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <!-- <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <!-- <div class="col-lg-2 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-menu
                                    ref="dialog"
                                    v-model="dateStartModal" 
                                    :return-value.sync="dateStartModel" 
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo dense
                                        clearable 
                                        v-model="dateStartModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" 
                                    @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-menu
                                    ref="dialog2"
                                    v-model="dateEndModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo dense
                                        clearable 
                                        v-model="dateEndModel"
                                        label="End Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" 
                                    :show-current="true" 
                                    @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="12">
                                <v-btn class="ml-10" 
                                color="info" elevation="1" medium @click="getData()">Search</v-btn>
                            </v-col>
                            <!-- <div class="col-lg-12 col-12 text-right">
                                <v-btn class="ml-10" color="info" elevation="2" small dense @click="getData()">Search</v-btn>
                            </div>  -->
                        </div>

                        <div class="col-lg-12 cardResult mb-2">
                            <div class="card elevation-5 mt-4" style="background-color: #E3F2FD;">
                            <div class="card-body">
                            <h5 class="card-title">Result</h5>
                                <v-data-table 
                                    :headers="headers"
                                    :items="itemLists" 
                                    class="elevation-1" 
                                    page-count="5" 
                                    :search="searchItem" 
                                    :loading="loadingDatatable" 
                                    dense>
                                        <template v-slot:top>
                                            <v-toolbar flat color="white">
                                                <!-- <div class="row">
                                                    <div class="col-6">
                                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export to Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                                    </div>
                                                </div> -->
                                                <td class="text-left w-50">
                                                    <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                                </td>
                                                <v-col cols="6" class="text-right">
                                                     <v-btn class="mb-4 mr-2 text-white" 
                                                        color="#0D47A1" elevation="2" small>
                                                        <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headerexcel"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                        </download-excel> 
                                                     <v-icon>mdi-arrow-down-bold-box</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <!-- <td class="text-right w-50">
                                                    <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                                </td> -->
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.activity`]="{ item }">
                                            <!-- <v-chip v-if="item.presentasi != 'N'" class="ma-2" color="cyan darken-3" small dense> -->
                                                <div v-if="item.presentasi != 'N'">
                                                    <span class="mb-1 badge badge-info">PENAWARAN PRODUK</span> <br />
                                                </div>
                                            <!-- </v-chip> -->
                                            <!-- <v-chip v-if="item.takingorder != 'N'" class="ma-2" color="cyan darken-3" small dense> -->
                                                <div v-if="item.takingorder != 'N'">
                                                    <span  class="mb-1 badge badge-warning">TAKING ORDER</span> <br />
                                                </div>
                                            <!-- </v-chip> -->
                                            <!-- <v-chip v-if="item.info != 'N'" class="ma-2" color="cyan darken-3" small dense> -->
                                                <div v-if="item.info != 'N'">
                                                    <span class="mb-1 badge badge-primary">INFO PROGRAM</span> <br />
                                                </div>
                                            <!-- </v-chip> -->
                                            <!-- <v-chip v-if="item.penagihan != 'N'" class="ma-2" color="cyan darken-3" small dense> -->
                                                <div v-if="item.penagihan != 'N'">
                                                    <span class="mb-1 badge badge-danger">PENAGIHAN</span> <br />
                                                </div>
                                            <!-- </v-chip> -->
                                            <!-- <v-chip v-if="item.maintenance != 'N'" class="ma-2" color="cyan darken-3" small dense> -->
                                                <div v-if="item.maintenance != 'N'">
                                                    <span  class="mb-1 badge badge-success">CUSTOMER VISIT</span> <br />
                                                </div>
                                            <!-- </v-chip> -->
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <!-- <v-chip class="ma-1" color="#26C6DA" elevation="2" dense v-on="on" small rounded @click="showItemMap(item)" dark><v-icon dark>mdi-google-maps</v-icon> </v-chip> -->
                                                        <v-icon small class="mr-2" @click="showItemMap(item)" v-on="on">mdi-google-maps</v-icon>
                                                    </template>
                                                    <span>Show Map</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-if="item.img_url != ''">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <!-- <v-chip class="ma-1" color="#283593" elevation="2" dense v-on="on" small rounded @click="showImageModal(item.img_url)" dark><v-icon dark>mdi-file-image</v-icon></v-chip> -->
                                                        <v-icon small class="mr-2" @click="showImageModal(item)" v-on="on">mdi-file-image</v-icon>
                                                    </template>
                                                    <span>Show Image</span>
                                                </v-tooltip>
                                            </td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <!-- <v-chip class="ma-1" v-if="item.feedback_status == '' || item.kacab_feedback == ''" color="red" elevation="2" dense v-on="on" small rounded @click="showFeedback(item)" dark><v-icon>mdi-comment-processing</v-icon></v-chip> -->
                                                        <!-- <v-chip class="ma-1" v-if="item.feedback_status == 'K'" color="yellow" elevation="2" dense v-on="on" small rounded @click="showFeedback(item)" dark><v-icon dark>mdi-comment-processing</v-icon></v-chip>
                                                        <v-chip class="ma-1" v-if="item.feedback_status == 'S'" color="green" elevation="2" dense v-on="on" small rounded @click="showFeedback(item)" dark><v-icon dark>mdi-comment-processing</v-icon></v-chip> -->
                                                        <v-icon v-if="item.feedback_status == '' || item.kacab_feedback == ''" color="red" small class="mr-2" @click="showFeedback(item)" v-on="on">mdi-comment-processing</v-icon>
                                                        <v-icon v-if="item.feedback_status == 'K'" color="yellow" small class="mr-2" @click="showFeedback(item)" v-on="on">mdi-comment-processing</v-icon>
                                                        <v-icon  v-if="item.feedback_status == 'S'" color="green" small class="mr-2" @click="showFeedback(item)" v-on="on">mdi-comment-processing</v-icon>
                                                    </template>
                                                    <span>Show Feedback</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-if="item.CUSTOMER_ID != 'NEW'">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <!-- <v-chip class="ma-1" color="green" elevation="2" dense v-on="on" small rounded @click="showTotalVisit(item)" dark><v-icon dark>mdi-run</v-icon></v-chip> -->
                                                        <v-icon small class="mr-2" @click="showTotalVisit(item)" v-on="on">mdi-eye</v-icon>
                                                    </template>
                                                    <span>Total Visit</span>
                                                </v-tooltip>
                                            </td>
                                        </template>
                                    </v-data-table>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-card>
            </div>
        </div>    
                            <!-- <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="dialog"
                                    v-model="dateStartModal" 
                                    :return-value.sync="dateStartModel" 
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateStartModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" 
                                    @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="dateEndModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateEndModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" 
                                    :show-current="true" 
                                    @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="mt-3" 
                                    color="info" elevation="2" large @click="getData()">Search</v-btn>
                                </v-col>  -->
                                
                            <!-- <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                                <div class="modal-dialog" style="max-width: 80%;">
                                    <div class="modal-content bgCustom3">
                                    <div class="modal-body">
                                        <div id="mapCanvas" style="height:500px;"></div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger  text-white" data-bs-dismiss="modal">Close</button>
                                    </div>
                                    </div>
                                </div>
                            </div> -->

                            <v-dialog v-model="dialogMap" transition="dialog-bottom-transition" max-width="1000px">
                                <div class="card elevation-5">
                                    <div class="card-body">
                                        <h5 class="card-title">Detail Location</h5>
                                        <v-spacer></v-spacer>
                                            <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                <tbody>
                                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                <td style="width: 80%;">
                                                                    <div class="d-flex">
                                                                        <img src="@/assets/logoex.png" height="35px" alt="">
                                                                        <!-- <p class="mt-4 ml-4">Sunrise Stell</p> -->
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <strong>Sales Activity</strong>
                                                                </td>
                                                            </tr>
                                                                    <!-- <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                        <td style="width: 100%;">
                                                                            <div class="d-flex">
                                                                                <img src="@/assets/logoex.png" height="30px" alt="">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <td>
                                                                    <strong>Sales Activity</strong>
                                                                </td> -->
                                                                </tbody>
                                                            </table>
                                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                <tbody>
                                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                        <td style="width:15%; padding-left: 5px;">
                                                                            Date
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            :
                                                                        </td>
                                                                        <td style="width:30%">
                                                                            {{ this.detailDateModel ? this.detailDateModel : '' }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                        <td style="width:15%; padding-left: 5px;">
                                                                            Customer
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            :
                                                                        </td>
                                                                        <td style="width:30%">
                                                                            {{ this.detailCustomerModel ? this.detailCustomerModel : '' }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                        <td style="width:15%; padding-left: 5px;">
                                                                            Address
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            :
                                                                        </td>
                                                                        <td colspan="4"  style="width:30%">
                                                                            {{ this.detailAddressModel ? this.detailAddressModel : '' }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                        <td style="width:15%; padding-left: 5px;">
                                                                            Remark
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            :
                                                                        </td>
                                                                        <td colspan="4" style="width:30%">
                                                                            {{ this.detailRemarkModel ? this.detailRemarkModel : '' }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width:15%; padding-left: 5px;">
                                                                            Salesman
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            :
                                                                        </td>
                                                                        <td style="width:30%">
                                                                            {{ this.detailSalesmanModel ? this.detailSalesmanModel : '' }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div id="mapCanvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                    <div class="card-footer text-muted text-right">
                                        <v-btn color="blue darken-1" text @click="dialogMap = !dialogMap">Close</v-btn>
                                    </div>
                                </div>
                            </v-dialog>

                            <!-- <div class="modal fade modal-xl" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
                                <div class="modal-dialog" style="max-width: 80%;">
                                    <div class="modal-content bgCustom3">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Sales Visit Feedback</h4>
                                        </div>
                                        <div class="modal-body">
                                            <div class="col-lg-12">
                                                <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback1" ></v-textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback2"></v-textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <v-btn class="" color="success" elevation="2" block rounded @click="storeFeedback()" >SUBMIT</v-btn>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <v-dialog v-model="dialogFeedback" max-width="700px">
                            <v-card class="p-2 rounded-l">
                                <v-card-title class="text-center">
                                    <v-row>
                                        <h4
                                        class="text-center"
                                        style="
                                        font-weight: 600;
                                        color: #053d76;
                                        margin-bottom: 15px;
                                        margin-top: 2px;">
                                        Report Kepala Cabang
                                        </h4>
                                    <v-divider
                                        style="
                                        border-top: 3px solid #0078d4;
                                        margin-top: 5px;"></v-divider>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                        <div class="row">
                                                    <div class="col-lg-12">
                                                        <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" outlined dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback1" ></v-textarea>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" outlined dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback2"></v-textarea>
                                                    </div>
                                                    <div class="col-md-6 text-center">
                                                        <v-btn class="ml-3" color="success" elevation="2" block @click="storeFeedback()" >SUBMIT</v-btn>
                                                    </div>
                                                    <div class="col-md-6 text-center">
                                                    <v-btn block class="ml-3 text-white" elevation="2" color="red" @click.stop="dialogFeedback=false">CLOSE</v-btn>
                                                </div>
                                                </div>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                            <!-- <v-dialog v-model="dialogFeedback" transition="dialog-bottom-transition" max-width="80%">
                                <div class="card elevation-5">
                                    <div class="card-body">
                                        <v-spacer></v-spacer>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback1" ></v-textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback2"></v-textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <v-btn class="" color="success" elevation="2" block rounded @click="storeFeedback()" >SUBMIT</v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-dialog> -->

                        <!-- <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content bgCustom3">
                                    <div class="modal-body">
                                        <v-img :src=imageUrl ></v-img>
                                    </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div> -->

                        <v-dialog v-model="dialogImage" max-width="1000px">
                            <v-card class="p-2 rounded-l">
                                <v-card-title class="text-center">
                                    <v-row>
                                        <h4
                                        class="text-center"
                                        style="
                                        font-weight: 600;
                                        color: #053d76;
                                        margin-bottom: 15px;
                                        margin-top: 2px;">
                                        Report Sales
                                        </h4>
                                    <v-divider
                                        style="
                                        border-top: 3px solid #0078d4;
                                        margin-top: 5px;"></v-divider>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>

                                  
                                        <v-row>
                                            <v-col class="col-12" sm="4" md="4">
                                                <v-card class="elevation-1 rounded-5">
                                                    <v-card-text class="mt-15">
                                                        <!-- <td v-if="item.img_url != ''"> -->
                                                        <v-img :src=imageUrl ></v-img>
                                                        <!-- </td> -->
                                                        <!-- <v-img v-for="file in doc_files" :key="file.file_name" :src="file.file_name"></v-img> -->
                                                        <p class="css-bqy72x mt-5">Besar file: maksimum (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col class="col-12" sm="8" md="8">
                                            <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                                                <v-col cols="12">
                                                    <h5 class="text-center" 
                                                    style="
                                                    font-weight: 600;
                                                    color: #053d76;
                                                    margin-bottom: 45px;
                                                    margin-top: 2px;"><span>Report Sales Info</span></h5>
                                                </v-col>
                                                <v-row>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">Sales ID<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="detailSalesIDModel" 
                                                        disabled 
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">Sales Name<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable 
                                                        v-model="detailSalesmanModel"
                                                        disabled
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">Customer Name<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="detailCustomerModel"
                                                        disabled 
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">Address<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="detailAddressModel"
                                                        disabled
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">City<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="detailCityModel"
                                                        disabled
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="4">
                                                    <h6 class="blue-lcd mb-1">Date<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable 
                                                        v-model="detailDateModel"
                                                        disabled
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="12">
                                                    <h6 class="blue-lcd mb-1">Lt Location<span class="red--text"></span></h6>
                                                    <v-text-field
                                                        dense
                                                        solo
                                                        clearable 
                                                        v-model="detailGPSAddressModel"
                                                        disabled
                                                    ></v-text-field> 
                                                </v-col>
                                                <v-col class="col-12" sm="3" md="12">
                                                    <h6 class="blue-lcd mb-1">Remark<span class="red--text"></span></h6>
                                                    <v-textarea v-model="detailRemarkModel" label="" disabled clearable outlined></v-textarea>
                                                </v-col>
                                                <v-row class="mt-1 ml-1">
                                                            <v-col cols="12">
                                                                <v-alert
                                                                icon="mdi-shield-lock-outline"
                                                                prominent
                                                                text
                                                                color="red"
                                                                >
                                                                Cek kembali data setiap customer pastikan data benar dan tidak ada kesalahan input data customer
                                                                </v-alert>
                                                            </v-col>
                                                        </v-row>
                                                </v-row>
                                                <v-col class="col-12" sm="3" md="12">
                                                    <v-btn block class=" mt-2 ml-1 text-white" color="red" @click="dialogImage=!dialogImage">Close</v-btn>
                                                </v-col>
                                            </v-container>
                                            </v-col>
                                            </v-row>
                                  
                                </v-card-text>
                                <!-- <v-card-actions class="text-center">
                                    <v-row justify="center">
                                        <v-col cols="12" xs="12" sm="3" md="2">
                                            
                                        </v-col>
                                    </v-row>
                                </v-card-actions> -->
                            </v-card>
                        </v-dialog>
                        <v-dialog
                v-model="dialogTotalVisit"
                max-width="1300px"
                >
                <v-card class="p-2 rounded-l">
                    <v-card-title class="text-center">
                        <v-row>
                            <h4
                            class="text-center"
                            style="
                            font-weight: 600;
                            color: #053d76;
                            margin-bottom: 15px;
                            margin-top: 2px;">
                            Details Kunjungan
                            </h4>
                        <v-divider
                            style="
                            border-top: 3px solid #0078d4;
                            margin-top: 5px;"></v-divider>
                        </v-row>
                    </v-card-title>
                        <v-card-actions class="text-center mt-10">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialogdetails=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                        <v-dialog v-model="dialogTotalVisit" transition="dialog-bottom-transition" max-width="1300px">
                            <div class="card elevation-5">
                                <v-card class="p-2 rounded-l">
                    <v-card-title class="text-center">
                        <v-row>
                            <h4
                            class="text-center"
                            style="
                            font-weight: 600;
                            color: #053d76;
                            margin-bottom: 15px;
                            margin-top: 2px;">
                            Details Kunjungan
                            </h4>
                        <v-divider
                            style="
                            border-top: 3px solid #0078d4;
                            margin-top: 5px;"></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-data-table :headers="totalVisitHeaders" height="350" :items="totalVisitItemLists" class="elevation-1" page-count="5" dense>
                        <template v-slot:[`item.total`]="{ item }">
                        <v-chip class="ma-2" color="success"> 
                            {{item.total}} 
                            </v-chip> 
                        </template>
                        </v-data-table>
                        <v-card-actions class="text-center mt-10">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialogTotalVisit=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>  
                    </div>
                </v-dialog>
        </div>
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KRI',
                disabled: false,
                href: '/admin/kri',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Visit Report',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            salesmanModel: '',
            salesmanLists: [],
            dateStartModel: '',
            dateEndModel: '',
            headers: [
                { text: 'Salesman', value: 'SALESMAN', align: 'left' },
                { text: 'Customer ID', value: 'CUSTOMER_ID', align: 'left' },
                { text: 'Customer Name', value: 'NAMA_CUSTOMER', align: 'left' },
                { text: 'Address', value: 'ALAMAT', align: 'left' },
                { text: 'City', value: 'KOTA', align: 'left' },
                { text: 'Date', value: 'TANGGAL', align: 'left' },
                { text: 'Gps Address', value: 'GPS_ADDRESS', align: 'left' },
                { text: 'Remark', value: 'REMARK', align: 'left' },
                { text: 'Activity', value: 'activity', align: 'left' },
                { text: '', value: 'actions', align: 'center' }
            ],
            itemLists: [],
            // totalVisitHeaders: [
            //     { text: 'Sales ID', align: 'start',
            //     sortable: true, value: 'salesid' },
            //     { text: 'Sales Name', value: 'SALESMAN' },
            //     { text: 'Nama Customer', value: 'NAMA_CUSTOMER' },
            //     { text: 'Date', value: 'TANGGAL' },
            //     { text: 'Remark', value: 'REMARK' },
            //     { text: 'Kunjugan', value: 'total' },
            // ],
            headerexcel: {
            'Customer ID':'CUSTOMER_ID',
            'Customer Name': 'NAMA_CUSTOMER',
            'Sales': 'SALESMAN',
            'Office': 'OFFICE',
            'Address' : 'ALAMAT',
            'City': 'KOTA',
            'Gps Address' :'GPS_ADDRESS',
            'Sales Latitude':'sales_latitude',
            'Sales Longitude':'sales_longitude',
            'Date': 'TANGGAL',
            'Remark': 'REMARK',
            },
            totalVisitHeaders: [
                { text: 'DATE', value: 'TANGGAL', align: 'left' },
                { text: 'SALESMAN', value: 'SALESMAN', align: 'left' },
                { text: 'CUSTOMER', value: 'NAMA_CUSTOMER', align: 'left' },
                { text: 'ADDRESS', value: 'GPS_ADDRESS', align: 'left' },
                { text: 'REMARK', value: 'REMARK', align: 'left' },
                { text: 'TOTAL VISIT', value: 'total', align: 'center' }
            ],
            totalVisitItemLists: [],
            searchItem: '',
            disabledExport:false,
            loadingDatatable: false,
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            dateStartModal: false,
            dateEndModal: false,
            mymap: '',
            kacabFeedbackModel: '',
            salesFeedbackModel: '',
            disabled_feedback1: true,
            disabled_feedback2: true,
            userGroup: '',
            tr_id: '',
            userGroup: null,
            appl_id: 'SALES ACTIVITY',
            exportToExcelLists: [],
            datas:[],
            imageUrl: '',
            search: null,
            findCustomerModel: '',
            dialogMap: false,
            dialogFeedback: false,
            dialogImage: false,
            dialogTotalVisit: false,
            detailDateModel: '',
            detailCustomerModel: '',
            detailAddressModel: '',
            detailCityModel: '',
            detailGPSAddressModel: '',
            detailRemarkModel: '',
            detailSalesIDModel: '',
            detailSalesmanModel: '',
            loading:false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";

            // this.dateStartModel = this.currentDate()
            // this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                if(res.data) {
                    this.userGroup = res.data.group
                    console.log(res.data);
                    
                    if(res.data.region.length == 1){
                        this.regionModel = res.data.region[0].region
                        this.regionLists = res.data.region
                        this.disabled_region = true
                    } else {
                        this.regionLists = res.data.region
                        this.disabled_region = false
                    }
                    if(res.data.office.length == 1){
                        this.officeModel = res.data.office[0].office_id
                        this.officeLists = res.data.office
                        this.disabled_office = true
                    } else {
                        this.officeLists = res.data.office
                        this.disabled_office = false
                    }
                    if(res.data.sales.length == 1){
                        this.salesmanModel = res.data.sales[0].SalesId
                        this.salesmanLists = res.data.sales
                        this.disabled_salesman = true
                    } else {
                        this.salesmanLists = res.data.sales
                        this.disabled_salesman = false
                    }
                }
                this.$store.dispatch('setOverlay', false)
            })
        },
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async GetCustomer(id){
            await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/GetCustomer?id=${id ? id : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.datas = res.data.result
            })
            
        }, 

      
        async getData(){

            if(this.dateStartModel == '' || this.dateStartModel == null){

                Swal.fire({
                    text: "Whoopss !!! Please Insert Start Date & End Date !",
                    icon: 'warning',
                })

                return false

                }
            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getData`, { 
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    customerid:this.findCustomerModel ? this.findCustomerModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                 //   this.exportToExcelLists = res.data.result2
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.itemLists = []
                  //  this.exportToExcelLists = []
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })

        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            }
        },

        async showItemMap(item){

            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }

            // $('#detailModal').modal('show')
            this.dialogMap = true
            this.detailDateModel = item.TANGGAL
            this.detailCustomerModel = item.NAMA_CUSTOMER
            this.detailAddressModel = item.GPS_ADDRESS
            this.detailRemarkModel = item.REMARK
            this.detailSalesmanModel = item.SALESMAN

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${item.sales_latitude}&lon=${item.sales_longitude}`)
            .then(res => {

                this.mymap = L.map('mapCanvas').setView([item.sales_latitude, item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([item.sales_latitude, item.sales_longitude],
                    {draggable: false,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup(this.detailCustomerModel)
                    .openPopup();

            })

        },

        async showFeedback(item){

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getFeedback?id=${item.tr_id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                // $('#feedbackModal').modal('show')
                this.dialogFeedback = true
                this.tr_id = item.tr_id
                this.kacabFeedbackModel = res.data.result[0].kacab_feedback
                this.salesFeedbackModel = res.data.result[0].sales_feedback
                if(this.userGroup == 'SALES KMB'){
                    this.disabled_feedback2 = false
                } else {
                    this.disabled_feedback1 = false
                }
            })

        },

        async storeFeedback(){
           this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/storeFeedback`, { 
                    group: this.userGroup ? this.userGroup : '',
                    tr_id: this.tr_id ? this.tr_id : '',
                    kacabFeedback: this.kacabFeedbackModel ? this.kacabFeedbackModel : '',
                    salesFeedback: this.salesFeedback ? this.salesFeedback : ''
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                Swal.fire({
                    text: 'Feedback submitted succesfully',
                    icon: 'success'
                })
                this.getData()
                // $('#feedbackModal').modal('hide')
                this.dialogFeedback = false
                this.$store.dispatch('setOverlay', false)
            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`
            const date = current.toISOString().slice(0,10);

            return date;
        },


      

        // exportToExcel(){

        //     const data = this.exportToExcelLists
        //     const fileName = "Sales-Activity-Report-Excel-Data"
        //     const exportType = exportFromJSON.types.csv

        //     if (data) exportFromJSON({ data, fileName, exportType })

        //     Swal.fire({
        //         title: 'Success',
        //         text: 'Data exported successfully, check your download folder.'
        //     })

        // },

        showImageModal(item){

            // $('#imgModal').modal('show')
            // console.log(imgUrl.img_url);

            this.detailDateModel = item.TANGGAL
            this.detailCustomerModel = item.NAMA_CUSTOMER
            this.detailAddressModel = item.ALAMAT
            this.detailCityModel = item.KOTA
            this.detailRemarkModel = item.REMARK
            this.detailSalesmanModel = item.SALESMAN
            this.detailSalesIDModel = item.salesid
            this.detailGPSAddressModel = item.GPS_ADDRESS

            this.dialogImage = true
            this.imageUrl = process.env.VUE_APP_URL + item.img_url
            
        },

        async showTotalVisit(id){

            if(id.CUSTOMER_ID == 'NEW'){

                Swal.fire({
                    title: 'Warning',
                    text: 'This customer has not been registered, cannot get total visit !'
                })

                return false
            }

            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/getTotalVisit?id=${id.CUSTOMER_ID}&startDate=${this.dateStartFormatted ? this.dateStartFormatted : ''}&endDate=${this.dateEndFormatted ? this.dateEndFormatted : ''}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.dialogTotalVisit = true

                this.totalVisitItemLists = res.data.result

            })

        },
        async  exportExcel() {
            if (this.dateStartModel == '' || this.dateStartModel == null) {
                Swal.fire({
                text: "Whoopss !!! Please Insert Start Date & End Date !",
                icon: 'warning',
                });
                return false;
            }
            this.$store.dispatch('setOverlay', true)
            let dateStartFormatted = '';
            let dateEndFormatted = '';

            if (this.dateStartModel) {
                dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g, "");
            }

            if (this.dateEndModel) {
                dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g, "");
            }

            try {
                const response = await axios.post(
                `${process.env.VUE_APP_URL}/api/kri/sales/VisitReport/ExportExcel?search=${this.$store.state.text}&customerid=${this.findCustomerModel ? this.findCustomerModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${this.officeModel ? this.officeModel : ''}&sales=${this.salesmanModel ? this.salesmanModel : ''}&start_date=${dateStartFormatted}&end_date=${dateEndFormatted}`,
                {},
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` },
                }
                );
                this.$store.dispatch('setOverlay', false)
                console.log(response.data);
                return response.data.data;
                } catch (error) {
                console.error(error);
                return null;
            }

        }

    },
    watch: {
        search (val) {
            val && val !== this.findCustomerModel && this.GetCustomer(val)
        },
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }


</style>